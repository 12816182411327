import { ApolloError, gql, QueryHookOptions, useQuery } from "@apollo/client";
import { Employee } from "types/employee";

export type EmployeeDetails = Pick<
  Employee,
  | "id"
  | "email"
  | "firstName"
  | "lastName"
  | "picture"
  | "accountingId"
  | "directSupervisor"
  | "externalId"
  | "status"
  | "role_id"
  | "role"
  | "department"
  | "personal"
  | "children"
  | "has_children_below_8"
  | "experiences"
  | "educations"
  | "certificates"
  | "skills"
> & {
  role?: { erpRole: string };
};

export interface GetEmployeeDetailsData {
  employee: EmployeeDetails;
}

export interface GetEmployeeDetailsVars {
  employeeId: string;
}

export const GET_EMPLOYEE_DETAILS = gql`
  query ($employeeId: uuid!) {
    employee: employees_by_pk(id: $employeeId) {
      id
      email
      firstName: first_name
      lastName: last_name
      externalId: external_id
      accountingId: accounting_id
      status
      directSupervisor: direct_supervisor {
        firstName: first_name
        id
        lastName: last_name
        picture
      }
      picture
      role {
        erpRole: erp_employee_role
      }
      role_id
      department {
        id
        name
      }
      personal: personal_details {
        id
        country
        city
        office {
          id
          name
        }
        phone
        email
        employment_start
        employment_end
        reason_for_termination
        is_treated_as_billable
        effigy_approval
        onboarding_buddy_id
        onboarding_buddy {
          id
          firstName: first_name
          lastName: last_name
        }
        linkedin
        github
        resume
        candidate_source_id
        source {
          id
          label
        }
        english_level
        english {
          value
          label
        }
        employee_grade_id
        grade {
          id
          label
        }
      }
      has_children_below_8
      children {
        id
        firstName: first_name
        lastName: last_name
        dob
        employeeId: employee_id
      }
      experiences: employee_experiences {
        id
        employeeId: employee_id
        company
        position
        project
        description
        technologies
        startDate: start_date
        endDate: end_date
        grade
      }
      educations: employee_educations {
        id
        employeeId: employee_id
        schoolName: school_name
        startDate: start_date
        endDate: end_date
        fieldOfStudy: field_of_study
      }
      certificates: employee_certificates {
        id
        employeeId: employee_id
        name
        date
      }
      skills: employee_skill_assessments {
        employeeId: employee_id
        name: skill
        proficiency: proficiency
        customProficiency: proficiency
      }
    }
  }
`;

export const useEmployeeDetails = (
  employeeId: string,
  options?: QueryHookOptions<GetEmployeeDetailsData, GetEmployeeDetailsVars>
) => {
  const { data, loading, error } = useQuery<
    GetEmployeeDetailsData,
    GetEmployeeDetailsVars
  >(GET_EMPLOYEE_DETAILS, {
    variables: { employeeId },
    ...options,
  });

  // handle employee id not found scenario
  if (data !== null && data?.employee === null) {
    return {
      data,
      loading,
      error: new ApolloError({
        errorMessage: `Employee with id: ${employeeId} not found`,
      }),
    };
  }

  return { data, loading, error };
};
