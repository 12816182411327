import { Button } from "components";
import { useURLSearchParams } from "hooks";
import React from "react";
import { useHistory } from "react-router-dom";
import { twMerge } from "tailwind-merge";

export type TabType<K> = {
  key: K;
  label: string;
  adornment?: React.ReactNode;
};

type TabsProps<K extends string> = {
  tabs: TabType<K>[];
  activeTab: K;
  setActiveTab: (key: K) => void;
  withSearchParams?: boolean;
  classes?: {
    root?: string;
  };
};

export const Tabs = <K extends string>({
  tabs,
  activeTab,
  setActiveTab,
  withSearchParams = true,
  classes,
}: TabsProps<K>) => {
  const { searchParams } = useURLSearchParams();
  const history = useHistory();

  return (
    <div
      className={twMerge(
        "flex flex-wrap gap-8 px-5 sm:gap-14 md:px-0",
        classes?.root
      )}
      role="tablist"
    >
      {tabs.map(({ label, key, adornment }) => {
        const isActive = activeTab === key;
        return (
          <Button
            key={key}
            tabIndex={isActive ? 0 : -1}
            role="tab"
            aria-selected={isActive}
            aria-controls={`tabpanel-${key}`}
            id={`tab-${key}`}
            className={`rounded-none border-b-2 px-0 py-2 text-sm transition-colors duration-300 ${
              isActive
                ? "border-purple-2 font-bold"
                : "border-transparent font-normal hover:border-grey-5"
            }`}
            onClick={() => {
              if (withSearchParams) {
                searchParams.set("tab", key);
                history.push({ search: `?${searchParams}` });
              }
              setActiveTab(key);
            }}
          >
            {label}
            {adornment}
          </Button>
        );
      })}
    </div>
  );
};
