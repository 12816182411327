/* eslint-disable no-undef */
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import API from "config/api";
import {
  AUTH0_CALIMS_NAME,
  AUTH_TOKEN_NAME,
  COOKIES,
  USER_CATEGORY_ROLES_ENUM,
  USER_ROLES_ENUM,
} from "config/constants";
import ROUTES from "config/routes";
import { useLocalStorage } from "hooks";

interface IClaims {
  "email"?: string;
  "id"?: string;
  "role"?: USER_ROLES_ENUM;
  "first_name"?: string;
  "last_name"?: string;
  "managerId"?: string;
  "externalId"?: string;
  "x-hasura-default-role"?: string;
  "x-hasura-allowed-roles"?: string[];
}

interface IUserData {
  externalId?: string;
  email: string;
  role: USER_ROLES_ENUM;
  managerId?: string;
  userId?: string;
  firstName?: string;
  lastName?: string;
  categoryRoleInErp?: USER_CATEGORY_ROLES_ENUM;
  picture?: string;
  allowedRoles: string[];
}

interface IAuthContext {
  logout: () => void;
  redirectToLogin: () => void;
  switchToEmployeePortal: () => void;
  switchToManagerPortal: () => void;
  user: IUserData | null;
  isAuthenticated: boolean;
  isInitialized: boolean;
  isManager: () => boolean;
  hasManagerAccount: () => boolean;
  token: string | null;
  removeToken: () => void;
  setToken: any;
}

export interface IDecodedToken {
  "claims": IClaims;
  "https://hasura.io/jwt/claims"?: IClaims;
  "exp": number;
  "external_id": string;
  "email": string;
  "role_in_erp": USER_CATEGORY_ROLES_ENUM;
  "user_id": string;
  "picture"?: string;
  "first_name": string;
  "last_name": string;
}

const AuthContext = createContext<IAuthContext>({} as IAuthContext);

const AuthContextProvider = ({ children }: PropsWithChildren) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<IUserData | null>(null);
  const [token, setToken] = useLocalStorage<string>(
    AUTH_TOKEN_NAME,
    localStorage.getItem(AUTH_TOKEN_NAME) as any
  );
  const history = useHistory();
  const { pathname } = useLocation();

  const removeToken = () => {
    localStorage.removeItem(AUTH_TOKEN_NAME);
  };

  const logout = useCallback(() => {
    let redirect = "";

    if ((user && user.role === "manager") || pathname.startsWith("/admin")) {
      redirect = `${process.env.REACT_APP_BACKEND_URL || ""}${
        API.logout
      }?isManager=true`;
    } else {
      redirect = `${process.env.REACT_APP_BACKEND_URL || ""}${API.logout}`;
    }
    removeToken();
    setIsAuthenticated(false);
    setUser(null);
    window.open(redirect, "_self");
  }, [user, pathname]);

  const isManager = useCallback((): boolean => {
    return !!(user && user.role === "manager");
  }, [user]);

  const hasManagerAccount = useCallback(
    () => user?.allowedRoles.includes("manager") || false,
    [user]
  );

  const redirectToLogin = useCallback(() => {
    const loginUrl = new URL(
      `${process.env.REACT_APP_BACKEND_URL || ""}${API.login}`
    );
    if (process.env.REACT_APP_LOCAL_REDIRECT === "true")
      loginUrl.searchParams.set("local_redirect", "true");

    // check pathname without trailing shash
    if (pathname.startsWith("/admin"))
      loginUrl.searchParams.set("manager_login", "true");

    window.open(loginUrl, "_self");
  }, [pathname]);

  const isTokenValid = useCallback((t: string) => {
    // todo: check token and in case where expired, redirect to session expired view
    try {
      const decodedToken: IDecodedToken = jwtDecode(t);
      const { exp: expires }: { exp: number } = decodedToken;
      const now = Math.ceil(Date.now() / 1000);
      return expires > now;
    } catch (e) {
      removeToken();
      return false;
    }
  }, []);
  const initAuth = useCallback(
    (token: string) => {
      if (token) {
        try {
          const decodedToken: IDecodedToken = jwtDecode(token || "");
          const { email }: { email: string } = decodedToken;
          if (!isTokenValid(token)) {
            logout();
          }
          // todo: refactor to have axios in separated service
          if (axios.defaults?.headers && axios.defaults.headers.common) {
            axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
          }
          setToken(token);
          const hasuraClaims = decodedToken[AUTH0_CALIMS_NAME] || {};
          const role = hasuraClaims["x-hasura-default-role"];
          const allowedRoles = hasuraClaims["x-hasura-allowed-roles"]!;
          let userData: IUserData;
          if (role === USER_ROLES_ENUM.EMPLOYEE) {
            userData = {
              categoryRoleInErp: decodedToken.role_in_erp,
              email: email,
              externalId: decodedToken.external_id,
              role: USER_ROLES_ENUM.EMPLOYEE,
              userId: decodedToken.user_id,
              firstName: decodedToken.first_name,
              lastName: decodedToken.last_name,
              picture: decodedToken.picture,
              allowedRoles,
            };
          } else if (role === USER_ROLES_ENUM.MANAGER) {
            userData = {
              email: email,
              role: USER_ROLES_ENUM.MANAGER,
              managerId: decodedToken.user_id,
              picture: decodedToken.picture,
              firstName: decodedToken.first_name,
              lastName: decodedToken.last_name,
              allowedRoles,
            };
          } else {
            throw Error("Unsupported hasura role");
          }

          setIsAuthenticated(true);
          setUser({
            ...userData,
          });

          setIsInitialized(true);
        } catch (e) {
          console.error(e);
        }
      }
    },
    [isTokenValid, setToken, logout]
  );

  const switchToEmployeePortal = useCallback(() => {
    const url = new URL(
      `${process.env.REACT_APP_BACKEND_URL || ""}${API.switchToEmployeePortal}`
    );
    if (process.env.REACT_APP_LOCAL_REDIRECT === "true")
      url.searchParams.set("local_redirect", "true");

    fetch(url, {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
      redirect: "follow",
    })
      .then((response) => {
        if (response.redirected) window.location.href = response.url;
        if (response.ok) return response.text();
        else throw Error();
      })
      .then((token) => {
        initAuth(token);
        history.push(ROUTES.employee.timesheetsDetails);
      })
      .catch((ex) => {
        toast.error("Failed to switch portals");
        console.error(ex);
      });
  }, [token, initAuth, history]);

  const switchToManagerPortal = useCallback(() => {
    const url = new URL(
      `${process.env.REACT_APP_BACKEND_URL || ""}${API.switchToManagerPortal}`
    );
    if (process.env.REACT_APP_LOCAL_REDIRECT === "true")
      url.searchParams.set("local_redirect", "true");

    fetch(url, {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
      redirect: "follow",
    })
      .then((response) => {
        if (response.redirected) window.location.href = response.url;
        if (response.ok) return response.text();
        else throw Error();
      })
      .then((token) => {
        initAuth(token);
        history.push(ROUTES.manager.timesheets);
      })
      .catch((ex) => {
        toast.error("Failed to switch portals");
        console.error(ex);
      });
  }, [token, initAuth, history]);

  useEffect(() => {
    if (pathname === "/userNotFound") {
      setIsInitialized(true);
      history.push(ROUTES.common.userNotFound);
      return;
    }

    let t = Cookies.get(COOKIES.hasuraToken) || null;
    if (t === null) {
      t = token;
    }
    if (t === null) {
      redirectToLogin();
      return;
    }

    if (isTokenValid(t)) {
      initAuth(t);
    } else {
      setIsInitialized(true);
      logout();
    }
    //TODO verify what dependencies should we add or it should be all used in useEffect; listing deps here causes an infinite rerender loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const context = useMemo(
    () => ({
      logout,
      user,
      token,
      isAuthenticated,
      isInitialized,
      removeToken,
      isManager,
      hasManagerAccount,
      setToken,
      redirectToLogin,
      switchToEmployeePortal,
      switchToManagerPortal,
    }),
    [
      logout,
      user,
      token,
      isAuthenticated,
      isInitialized,
      isManager,
      hasManagerAccount,
      setToken,
      redirectToLogin,
      switchToEmployeePortal,
      switchToManagerPortal,
    ]
  );

  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
};

const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuthContext must be used within a AuthContextProvider");
  }
  return context;
};

export { AuthContextProvider, useAuthContext };
